define("ember-link/link", ["exports", "@ember/application", "@ember/debug", "@ember/object", "@glimmer/tracking", "@ember/runloop"], function (_exports, _application, _debug, _object, _tracking, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.UILink = void 0;
  _exports.isQueryParams = isQueryParams;
  var _class, _descriptor, _class3, _descriptor2, _descriptor3;
  /* eslint-disable max-classes-per-file */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // import RouteInfo from '@ember/routing/-private/route-info';
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const MAIN_BUTTON = 0;
  function isQueryParams(maybeQueryParam) {
    return (maybeQueryParam === null || maybeQueryParam === void 0 ? void 0 : maybeQueryParam.isQueryParams) && typeof maybeQueryParam.values === 'object';
  }

  // eslint-disable-next-line @typescript-eslint/ban-types

  function freezeParams(params) {
    if (false /* DEBUG */) {
      if (params.models) Object.freeze(params.models);
      if (params.query) Object.freeze(params.query);
      return Object.freeze(params);
    }
    return params;
  }
  function isUnmodifiedLeftClick(event) {
    return event.button === MAIN_BUTTON && !event.ctrlKey && !event.metaKey;
  }
  function isMouseEvent(event) {
    return typeof event === 'object' && event !== null && 'button' in event;
  }
  let Link = _exports.default = (_class3 = class Link {
    constructor(linkManager, params) {
      _initializerDefineProperty(this, "_params", _descriptor2, this);
      // eslint-disable-next-line @typescript-eslint/naming-convention
      _defineProperty(this, "_linkManager", void 0);
      _initializerDefineProperty(this, "_knownRouteQps", _descriptor3, this);
      (0, _application.setOwner)(this, (0, _application.getOwner)(linkManager));
      this._linkManager = linkManager;
      this._params = freezeParams(params);

      //In order to support `known` we can't read Route._qp inside the getter, because it will entangle autotracking
      //Is there a better way to do this?, router.currentRouteName is not always available
      //Maybe this._linkManager.router._router.url instead of window.location.pathname?
      if (this._linkManager.router.currentRouteName) {
        var _getOwner$lookup;
        this._knownRouteQps = (_getOwner$lookup = (0, _application.getOwner)(this).lookup(`route:${this._linkManager.router.currentRouteName}`)) === null || _getOwner$lookup === void 0 ? void 0 : _getOwner$lookup._qp;
      } else {
        var _this$_linkManager$ro, _window;
        const routeName = (_this$_linkManager$ro = this._linkManager.router.recognize(((_window = window) === null || _window === void 0 || (_window = _window.location) === null || _window === void 0 ? void 0 : _window.pathname) || this._linkManager.router._router.url)) === null || _this$_linkManager$ro === void 0 ? void 0 : _this$_linkManager$ro.name;
        const cb = () => {
          var _getOwner$lookup2;
          this._knownRouteQps = (_getOwner$lookup2 = (0, _application.getOwner)(this).lookup(`route:${routeName}`)) === null || _getOwner$lookup2 === void 0 ? void 0 : _getOwner$lookup2._qp;
        };
        if (routeName) {
          (0, _runloop.next)(this, cb);
        }
      }
    }
    get mode() {
      var _this$_params$mode;
      return (_this$_params$mode = this._params.mode) !== null && _this$_params$mode !== void 0 ? _this$_params$mode : 'none';
    }
    get _routeArgs() {
      const {
        routeName,
        models,
        queryParams
      } = this;
      if (queryParams) {
        return [routeName, ...models,
        // Cloning `queryParams` is necessary, since we freeze it, but Ember
        // wants to mutate it.
        {
          queryParams: {
            ...queryParams
          }
        }];
      }
      return [routeName, ...models];
    }

    /**
     * Whether this route is currently active, including potentially supplied
     * models and query params.
     */
    get isActive() {
      if (!this._linkManager.isRouterInitialized) return false;
      this._linkManager.currentTransitionStack; // eslint-disable-line @typescript-eslint/no-unused-expressions
      return this._linkManager.router.isActive(...this._routeArgs);
    }

    /**
     * Whether this route is currently active, including potentially supplied
     * models, but ignoring query params.
     */
    get isActiveWithoutQueryParams() {
      if (!this._linkManager.isRouterInitialized) return false;
      this._linkManager.currentTransitionStack; // eslint-disable-line @typescript-eslint/no-unused-expressions
      return this._linkManager.router.isActive(this.routeName,
      // Unfortunately TypeScript is not clever enough to support 'rest'
      // parameters in the middle.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...this.models);
    }

    /**
     * Whether this route is currently active, but ignoring models and query
     * params.
     */
    get isActiveWithoutModels() {
      if (!this._linkManager.isRouterInitialized) return false;
      this._linkManager.currentTransitionStack; // eslint-disable-line @typescript-eslint/no-unused-expressions
      return this._linkManager.router.isActive(this.routeName);
    }

    /**
     * Whether this route is currently being transitioned into / entered.
     */
    get isEntering() {
      return this._isTransitioning('to');
    }

    /**
     * Whether this route is currently being transitioned out of / exited.
     */
    get isExiting() {
      return this._isTransitioning('from');
    }

    /**
     * The URL for this link that you can pass to an `<a>` tag as the `href`
     * attribute.
     */
    createNoneUrl() {
      if (!this._linkManager.isRouterInitialized) return '';
      return this._linkManager.router.urlFor(...this._routeArgs);
    }
    createKnownUrl(routeQps) {
      if (!this._linkManager.isRouterInitialized) return '';
      const {
        routeName,
        models,
        queryParams = {}
      } = this;
      let mergedQps = {};
      const cloned = {
        ...queryParams
      };
      let final = {};
      mergedQps = {
        ...queryParams
      };
      this._linkManager.routing.normalizeQueryParams(routeName, models, mergedQps);
      const qp = typeof routeQps === 'function' ? routeQps() : routeQps;
      if (qp && qp.qps.length > 0) {
        const {
          qps
        } = qp;
        for (const q of qps) {
          final[q.urlKey] = mergedQps[q.urlKey];
        }
        final = {
          ...final,
          ...cloned
        };
      } else {
        final = {
          ...cloned
        };
      }
      final = {
        queryParams: {
          ...final
        }
      };
      return this._linkManager.router.urlFor(routeName, ...models, final);
    }
    createAllUrl() {
      if (!this._linkManager.isRouterInitialized) return '';
      const {
        routeName,
        models,
        queryParams = {}
      } = this;
      return this._linkManager.routing.generateURL(routeName, models, queryParams);
    }
    get trackedAllUrl() {
      this._linkManager.currentTransitionStack;
      return this.createAllUrl();
    }
    get trackedKnownUrl() {
      this._linkManager.currentTransitionStack;
      return this.createKnownUrl(() => {
        var _getOwner$lookup3;
        return (_getOwner$lookup3 = (0, _application.getOwner)(this).lookup(`route:${this._linkManager.router.currentRouteName}`)) === null || _getOwner$lookup3 === void 0 ? void 0 : _getOwner$lookup3._qp;
      });
    }
    get knownUrl() {
      return this.createKnownUrl(this._knownRouteQps);
    }
    get noneUrl() {
      return this.createNoneUrl();
    }
    get allUrl() {
      return this.createAllUrl();
    }
    get url() {
      switch (this.mode) {
        case 'known':
          return this.knownUrl;
        case 'all':
          return this.allUrl;
        case 'tracked-known':
          return this.trackedKnownUrl;
        case 'tracked-all':
          return this.trackedAllUrl;
        case 'none':
          return this.noneUrl;
        default:
          return this.noneUrl;
      }
    }

    /**
     * Deprecated alias for `url`.
     */
    get href() {
      (false && !(false) && (0, _debug.deprecate)('`href` is deprecated. Use `url` instead.', false, {
        id: 'ember-link.link.href',
        until: '2.0.0',
        for: 'ember-link',
        since: {
          available: '1.1.0',
          enabled: '1.1.0'
        }
      }));
      return this.url;
    }

    /**
     * Alias for `url`.
     *
     * Allows for more ergonomic composition as query parameters.
     *
     * ```hbs
     * {{link 'foo' query=(hash bar=(link 'bar'))}}
     * ```
     */
    toString() {
      return this.url;
    }

    /**
     * The `RouteInfo` object for the target route.
     */
    // get route(): RouteInfo {
    //   return this._linkManager.router.recognize(this.url);
    // }

    /**
     * The target route name of this link.
     */
    get routeName() {
      return this._params.route;
    }

    /**
     * The fully qualified target route name of this link.
     */
    get qualifiedRouteName() {
      // Ignore `Property 'recognize' does not exist on type 'RouterService'.`
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const routeInfo = this._linkManager.router.recognize(this.url);
      return routeInfo.name;
    }

    /**
     * The route models passed in this link.
     */
    get models() {
      var _this$_params$models;
      return (_this$_params$models = this._params.models) !== null && _this$_params$models !== void 0 ? _this$_params$models : [];
    }

    /**
     * The query params for this link, if specified.
     */
    get queryParams() {
      return this._params.query;
    }
    _isTransitioning(direction) {
      var _this$_linkManager$cu, _this$_linkManager$cu2;
      return (_this$_linkManager$cu = (_this$_linkManager$cu2 = this._linkManager.currentTransitionStack) === null || _this$_linkManager$cu2 === void 0 ? void 0 : _this$_linkManager$cu2.some(transition => {
        var _transition$direction;
        return ((_transition$direction = transition[direction]) === null || _transition$direction === void 0 ? void 0 : _transition$direction.name) === this.qualifiedRouteName;
      })) !== null && _this$_linkManager$cu !== void 0 ? _this$_linkManager$cu : false;
    }

    /**
     * Transition into the target route.
     */
    transitionTo() {
      var _this$_params$onTrans, _this$_params;
      (false && !(this._linkManager.isRouterInitialized) && (0, _debug.assert)('You can only call `transitionTo`, when the router is initialized, e.g. when using `setupApplicationTest`.', this._linkManager.isRouterInitialized));
      (_this$_params$onTrans = (_this$_params = this._params).onTransitionTo) === null || _this$_params$onTrans === void 0 || _this$_params$onTrans.call(_this$_params);
      return this._linkManager.router.transitionTo(this.url);
    }

    /**
     * Transition into the target route while replacing the current URL, if
     * possible.
     */
    replaceWith() {
      var _this$_params$onRepla, _this$_params2;
      (false && !(this._linkManager.isRouterInitialized) && (0, _debug.assert)('You can only call `replaceWith`, when the router is initialized, e.g. when using `setupApplicationTest`.', this._linkManager.isRouterInitialized));
      (_this$_params$onRepla = (_this$_params2 = this._params).onReplaceWith) === null || _this$_params$onRepla === void 0 || _this$_params$onRepla.call(_this$_params2);
      return this._linkManager.router.replaceWith(this.url);
    }
  }, (_descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "_params", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "_knownRouteQps", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "transitionTo", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "transitionTo"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "replaceWith", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "replaceWith"), _class3.prototype)), _class3);
  /**
   * @deprecated This class will be removed in version 3 of `ember-link` in favor
   * of only having one {@link Link} class
   */
  let UILink = _exports.UILink = (_class = class UILink extends Link {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_params", _descriptor, this);
    }
    preventDefault(event) {
      var _this$_params$prevent;
      if (((_this$_params$prevent = this._params.preventDefault) !== null && _this$_params$prevent !== void 0 ? _this$_params$prevent : true) && typeof (event === null || event === void 0 ? void 0 : event.preventDefault) === 'function') {
        event.preventDefault();
      }
    }

    /**
     * Transition into the target route.
     *
     * Optionally call `preventDefault()`, if an `Event` is passed in.
     */
    transitionTo(event) {
      if (isMouseEvent(event) && !isUnmodifiedLeftClick(event)) return;

      // Intentionally putting this *before* the assertion to prevent navigating
      // away in case of a failed assertion.
      this.preventDefault(event);
      return super.transitionTo();
    }

    /**
     * Transition into the target route while replacing the current URL, if
     * possible.
     *
     * Optionally call `preventDefault()`, if an `Event` is passed in.
     */
    replaceWith(event) {
      if (isMouseEvent(event) && !isUnmodifiedLeftClick(event)) return;

      // Intentionally putting this *before* the assertion to prevent navigating
      // away in case of a failed assertion.
      this.preventDefault(event);
      return super.replaceWith();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_params", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitionTo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "transitionTo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "replaceWith", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "replaceWith"), _class.prototype)), _class);
});