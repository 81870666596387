define("ember-href-to/helpers/href-to", ["exports", "@ember/component/helper", "@ember/application", "@ember/object"], function (_exports, _helper, _application, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hrefTo = hrefTo;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function hrefTo(routing, params) {
    return routing.generateURL(...getParamsForGenerateURL(params));
  }
  function getParamsForGenerateURL(params) {
    params = params.slice(); // create a copy
    let targetRouteName = params.shift(); // the first param is always the target route name
    let lastParam = params[params.length - 1]; // the last param might be queryParams
    let queryParams;
    if (lastParam && lastParam.isQueryParams) {
      queryParams = params.pop().values;
    } else {
      queryParams = {};
    }
    let models = params; // the remainder are the models
    return [targetRouteName, models, queryParams];
  }
  let HrefToHelper = _exports.default = (_class = class HrefToHelper extends _helper.default {
    get routing() {
      return (0, _application.getOwner)(this).lookup('service:-routing');
    }
    get router() {
      return (0, _application.getOwner)(this).lookup('service:router');
    }
    _recompute() {
      this.recompute();
    }
    init() {
      super.init();
      if (this.router && this.router.on) {
        // skip if the router service is mocked
        this.router.on('routeDidChange', this._recompute);
      }
    }
    willDestroy() {
      super.willDestroy();
      if (this.router && this.router.on) {
        this.router.off('routeDidChange', this._recompute);
      }
    }
    compute(params, namedArgs) {
      if (namedArgs.params) {
        return hrefTo(this.routing, namedArgs.params);
      } else {
        return hrefTo(this.routing, params);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "_recompute", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_recompute"), _class.prototype)), _class);
});