define("ember-concurrency/-private/external/task-factory", ["exports", "ember-concurrency/-private/external/scheduler/scheduler", "ember-concurrency/-private/external/scheduler/policies/unbounded-policy", "ember-concurrency/-private/external/scheduler/policies/enqueued-policy", "ember-concurrency/-private/external/scheduler/policies/drop-policy", "ember-concurrency/-private/external/scheduler/policies/keep-latest-policy", "ember-concurrency/-private/external/scheduler/policies/restartable-policy", "ember-concurrency/-private/external/task/task", "ember-concurrency/-private/external/task/task-group"], function (_exports, _scheduler, _unboundedPolicy, _enqueuedPolicy, _dropPolicy, _keepLatestPolicy, _restartablePolicy, _task, _taskGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TaskFactory = void 0;
  _exports.getModifier = getModifier;
  _exports.hasModifier = hasModifier;
  _exports.registerModifier = registerModifier;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function assertModifiersNotMixedWithGroup(obj) {
    if (obj._hasSetConcurrencyConstraint && obj._taskGroupPath) {
      throw new Error(`Cannot use both 'group' and other concurrency-constraining task modifiers (e.g. 'drop', 'enqueue', 'restartable')`);
    }
  }
  function assertUnsetBufferPolicy(obj) {
    if (obj._hasSetBufferPolicy) {
      throw new Error(`Cannot set multiple buffer policies on a task or task group. ${obj._schedulerPolicyClass} has already been set for task or task group '${obj.name}'`);
    }
  }
  const MODIFIER_REGISTRY = {
    enqueue: (factory, value) => value && factory.setBufferPolicy(_enqueuedPolicy.default),
    evented: (factory, value) => value && factory.setEvented(value),
    debug: (factory, value) => value && factory.setDebug(value),
    drop: (factory, value) => value && factory.setBufferPolicy(_dropPolicy.default),
    group: (factory, groupName) => factory.setGroup(groupName),
    keepLatest: (factory, value) => value && factory.setBufferPolicy(_keepLatestPolicy.default),
    maxConcurrency: (factory, maxConcurrency) => factory.setMaxConcurrency(maxConcurrency),
    onState: (factory, onStateCallback) => factory.setOnState(onStateCallback),
    restartable: (factory, value) => value && factory.setBufferPolicy(_restartablePolicy.default)
  };

  /**
   * Callback type defining a task modifier
   *
   * @callback TaskFactory~TaskModifier
   * @param {TaskFactory} factory
   * @param {*} taskModifierOption
   */

  /**
   * Registers a new modifier with the modifier registry
   *
   * @param {string} name Name of the modifier
   * @param {TaskFactory~TaskModifier} callback
   */
  function registerModifier(name, callback) {
    if (MODIFIER_REGISTRY[name]) {
      throw new Error(`A modifier with the name '${name}' has already been defined.`);
    }
    MODIFIER_REGISTRY[name] = callback;
  }

  /**
   * Returns a specified modifier, if it exists in the registry
   *
   * @param {string} name Name of the modifier
   * @returns {TaskFactory~TaskModifier?}
   */
  function getModifier(name) {
    return MODIFIER_REGISTRY[name];
  }

  /**
   * Returns whether a specified modifier exists in the registry
   *
   * @param {string} name Name of the modifier
   * @returns {boolean}
   */
  function hasModifier(name) {
    return name in MODIFIER_REGISTRY;
  }

  /**
   * Factory used for instantiating Tasks and Task Groups. Mostly for internal
   * use, but some public APIs exposed via the Task Modifier APIs.
   *
   * <style>
   *  .ignore-this--this-is-here-to-hide-constructor,
   *  #TaskFactory { display: none }
   * </style>
   *
   * @class TaskFactory
   */
  class TaskFactory {
    constructor(name = '<unknown>', taskDefinition = null, options = {}) {
      _defineProperty(this, "_debug", null);
      _defineProperty(this, "_enabledModifiers", []);
      _defineProperty(this, "_hasSetConcurrencyConstraint", false);
      _defineProperty(this, "_hasSetBufferPolicy", false);
      _defineProperty(this, "_hasEnabledEvents", false);
      _defineProperty(this, "_maxConcurrency", null);
      _defineProperty(this, "_onStateCallback", (state, taskable) => taskable.setState(state));
      _defineProperty(this, "_schedulerPolicyClass", _unboundedPolicy.default);
      _defineProperty(this, "_taskGroupPath", null);
      this.name = name;
      this.taskDefinition = taskDefinition;
      this.options = options;
      this._processModifierOptions(options);
    }

    /**
     * Returns a new Task bound to the given context
     *
     * @protected
     * @param {*} context
     * @returns {Task}
     */
    createTask(context) {
      let options = this.getTaskOptions(context);
      return new _task.Task(Object.assign({
        generatorFactory: args => this.taskDefinition.apply(context, args)
      }, options));
    }

    /**
     * Returns a new TaskGroup bound to the given context
     *
     * @protected
     * @param {*} context
     * @returns {Task}
     */
    createTaskGroup(context) {
      let options = this.getTaskOptions(context);
      return new _taskGroup.TaskGroup(options);
    }

    /**
     * Returns a modifier callback with the given name bound to this TaskFactory,
     * if registered.
     *
     * @protected
     * @param {string} name
     * @returns {TaskFactory~TaskModifier?}
     */
    getModifier(name) {
      if (hasModifier(name)) {
        return MODIFIER_REGISTRY[name].bind(null, this);
      }
    }

    /**
     * Returns the options provided to TaskFactory
     *
     * @public
     * @returns {object}
     */
    getOptions() {
      return this.options;
    }

    /**
     * Returns a new Scheduler instance
     *
     * @protected
     * @param {*} schedulerPolicy
     * @param {boolean} stateTrackingEnabled
     * @returns {Scheduler}
     */
    getScheduler(schedulerPolicy, stateTrackingEnabled) {
      return new _scheduler.default(schedulerPolicy, stateTrackingEnabled);
    }

    /**
     * Returns the options to pass to a Task or TaskGroup constructor
     *
     * @protected
     * @param {*} context
     * @returns {object}
     */
    getTaskOptions(context) {
      let group, scheduler;
      let onStateCallback = this._onStateCallback;
      if (this._taskGroupPath) {
        group = context[this._taskGroupPath];
        if (!(group instanceof _taskGroup.TaskGroup)) {
          throw new Error(`Expected group '${this._taskGroupPath}' to be defined but was not found.`);
        }
        scheduler = group.scheduler;
      } else {
        let schedulerPolicy = new this._schedulerPolicyClass(this._maxConcurrency);
        scheduler = this.getScheduler(schedulerPolicy, onStateCallback && typeof onStateCallback === 'function');
      }
      return {
        context,
        debug: this._debug,
        name: this.name,
        group,
        scheduler,
        hasEnabledEvents: this._hasEnabledEvents,
        onStateCallback,
        enabledModifiers: this._enabledModifiers,
        modifierOptions: this.getOptions()
      };
    }

    /**
     * Sets the Scheduler buffer policy class to the specified value.
     *
     * Will raise an assertion if a buffer policy has already been specified
     *
     * @param {*} policy
     * @returns {TaskFactory}
     */
    setBufferPolicy(policy) {
      assertUnsetBufferPolicy(this);
      this._hasSetBufferPolicy = true;
      this._hasSetConcurrencyConstraint = true;
      this._schedulerPolicyClass = policy;
      assertModifiersNotMixedWithGroup(this);
      return this;
    }

    /**
     * Sets debug mode
     *
     * @param {boolean} enabled
     * @returns {TaskFactory}
     */
    setDebug(enabled) {
      this._debug = enabled;
      return this;
    }

    /**
     * Sets whether Task will dispatch Task events or not
     *
     * @param {boolean} enabled
     * @returns {TaskFactory}
     */
    setEvented(enabled) {
      this._hasEnabledEvents = enabled;
      return this;
    }

    /**
     * Sets Scheduling policy's `maxConcurrency`
     *
     * @param {number} maxConcurrency
     * @returns {TaskFactory}
     */
    setMaxConcurrency(maxConcurrency) {
      this._hasSetConcurrencyConstraint = true;
      this._maxConcurrency = maxConcurrency;
      return this;
    }

    /**
     * Assigns Task created from this factory to the specified group name
     *
     * @param {string} group
     * @returns {TaskFactory}
     */
    setGroup(group) {
      this._taskGroupPath = group;
      return this;
    }

    /**
     * Sets the name of tasks created from this factory
     *
     * @param {string} name
     * @returns {TaskFactory}
     */
    setName(name) {
      this.name = name;
      return this;
    }

    /**
     * Sets the callback used on state updates. Can be set to null to disable
     * state tracking on tasks.
     *
     * @param {function} onStateCallback
     * @returns {TaskFactory}
     */
    setOnState(onStateCallback) {
      this._onStateCallback = onStateCallback;
      return this;
    }

    /**
     * Sets the definition for tasks created from this factory
     *
     * @param {*} taskDefinition
     * @returns {TaskFactory}
     */
    setTaskDefinition(taskDefinition) {
      this.taskDefinition = taskDefinition;
      return this;
    }
    _processModifierOptions(options) {
      for (let key of Object.keys(options)) {
        let value = options[key];
        let modifier = this.getModifier(key);
        if (typeof modifier === 'function' && modifier(value)) {
          this._enabledModifiers.push(key);
        }
      }
    }
  }
  _exports.TaskFactory = TaskFactory;
});