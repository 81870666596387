define("ember-capacitor-events/services/capacitor-events", ["exports", "@ember/service", "@ember/debug", "@ember/utils", "@ember/object/evented"], function (_exports, _service, _debug, _utils, _evented) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class CapacitorEventsService extends _service.default.extend(_evented.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "_registeredPlugins", null);
      _defineProperty(this, "_plugins", null);
    }
    willDestroy() {
      this._teardownListeners();
      super.willDestroy(...arguments);
    }
    loadPlugins(plugins) {
      this._plugins = plugins;
      return this;
    }
    setupListeners() {
      let registeredPlugins = [];
      let plugins = this._plugins;
      (false && !((0, _utils.isBlank)(this._registeredPlugins)) && (0, _debug.assert)('plugins have already been set up', (0, _utils.isBlank)(this._registeredPlugins)));
      plugins.forEach(pluginObj => {
        let {
          plugin: Plugin,
          events
        } = pluginObj;
        registeredPlugins.push(Plugin);
        events.forEach(name => {
          const listener = {
            name,
            method: e => {
              this.trigger(name, e);
            }
          };
          Plugin.addListener(listener.name, listener.method);
        });
      });
      this._registeredPlugins = registeredPlugins;
    }
    _teardownListeners() {
      this._registeredPlugins.forEach(Plugin => {
        Plugin.removeAllListeners();
      });
      this._registeredPlugins = null;
    }
  }
  _exports.default = CapacitorEventsService;
});